import * as React from "react";
import { Helmet } from "react-helmet";

import YorkImage from "../components/images/York";
import PointsImage from "../images/svg/points.svg";
import PageHeader from "../components/PageHeader";
import PageSection from "../components/PageSection";
import Layout from "../components/layout/Layout";

interface SectionProps {
    title: string;
    className?: string;
}

const Section = (props: React.PropsWithChildren<SectionProps>) => {
    const { title, children, className } = props;

    return (
        <div className={className}>
            <section className="mt-5 p-5 rounded-lg border border-solid border-gray shadow" style={{boxShadow: "0 10px 28px rgba(0,0,0,.08)"}}>
                <h2 className="text-xl text-primary-default">{title}</h2>
                {children}
            </section>
        </div>
    )
}

const Services = () => {
    return (
        <Layout>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Further information about Eborsoft."></meta>
            </Helmet>
            <PageHeader title="We love software.">
                Our aim is to help make technology work for you.
            </PageHeader>
            <section className="pt-5 md:pt-15 container mx-auto px-8">
                <div className="flex flex-col lg:flex-row">
                    <Section title="The Mission" className="order-2 lg:order-1 w-full lg:w-2/3">
                        <p>
                            Technology is becoming more pervasive and powerful so constant evolution and change can make it increasingly
                            difficult to know if you and your business are getting the most out of what's currently available or
                            what to do about it. It can be difficult to understand what the most cost effective solutions are and how
                            to implement them.
                        </p>

                        <p className="mt-5">
                            Eborsoft's aim is to help businesses with their technology journey. Our teams has vast experience in both the
                            public and private sector and have seen the challenges businesses face first hand. We also know the issues that
                            can arise when needing to balance between maintaining existing systems and using new tech to push a business forward.
                        </p>

                        <p className="mt-5">
                            Whether it be discussing and implementing new software solutions or helping replace that problematic legacy system,
                            we can assist. Have an in-house development team already? Great! We can help add some extra horsepower to get that
                            large project over the line, take on projects your team doesn't have time to do, or provide technical advice for those
                            times you're out of ideas.
                        </p>
                    </Section>
                    <div className="order-1 lg:order-2 w-full lg:w-1/3 xl:w-1/4 flex items-center justify-center lg:p-8">
                        <img src={PointsImage} className="w-1/2 lg:w-full" alt="Diagram of fundamentals" />
                    </div>
                </div>

                <Section title="The Approach">
                    <p>
                        All of our work is underpinned with two core pillars: <strong>Communication</strong> and <strong>Quality</strong>.
                    </p>
                    <p className="mt-5">
                        We treat all of our customers as technology partners in projects undertaken and take a pragmatic and plain-English approach
                        throughout an engagement, from initial discussions all the way up to handoff and beyond. Unlike many larger software businesses
                        we operate a more boutique service, limiting the number of concurrent projects we take on at any time to ensure we can give
                        our full attention, and best service possible to all of our customers.
                    </p>
                    <p className="mt-5">
                        Software engineering projects are architected and designed from the ground up to be robust and maintainable. Where
                        customers may wish to use their own long-term maintenance solutions, Eborsoft can provide a formal handoff to
                        the technical partner of choice, ensuring the longevity of projects. Eborsoft can also provide maintenance
                        and cloud hosting programmes should any of these be required.
                    </p>
                </Section>

                <PageSection image={
                    <div className="mt-4 md:mt-0 md:mr-4">
                        <YorkImage />
                    </div>
                    } header="The Place" shadow reverse>
                    <p>
                        Eborsoft is based in the small but thriving city of York, England. We are a team of some of the most talented in the industry,
                        made up of individuals who live and breathe modern technology solutions. With an enviable track record of delivering software
                        in diverse sectors such as manufacturing, law enforcement, telecommunications and security, our team has what it takes to deliver best
                        in class software solutions.
                    </p>
                </PageSection>
            </section>
        </Layout>
    );
};

export default Services;