import clsx from "clsx";
import * as React from "react";

export interface PageSectionProps {
    header: string;
    image?: React.ReactNode;
    shadow?: boolean;
    className?: string;
    reverse?: boolean;
}

const PageSection = (props: React.PropsWithChildren<PageSectionProps>) => {
    const {
        children,
        header,
        image = null,
        className,
        shadow = false,
        reverse = false,
    } = props;

    let outerClassName = "";
    if (shadow) {
        outerClassName = "mt-5 p-5 rounded-lg border border-solid border-gray shadow-lg"
    }

    outerClassName = clsx(outerClassName, className);

    return (
        <section className={outerClassName}>
            <div className="flex flex-col md:flex-row">
                <div className={clsx(
                        {"md:basis-2/3": !!image, "md:basis-full": !image },
                        reverse ? ["order-1", "md:order-2"] : ["order-2", "md:order-1"])}>
                    <h2 className="text-xl text-primary-default">{header}</h2>
                    {children}
                </div>
                {image &&
                <div className={clsx("w-1/2", "md:basis-1/3", "mx-auto", reverse ? ["order-2", "md:order-1"] : ["order-1", "md:order-2"])}>
                    {image}
                </div>}
            </div>
        </section>
    );
};

export default PageSection;
